
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LocalStorageService } from '../shared/local-storage.service';
import { LoggerService } from './logger.service';

import { environment } from '../../../../environments/environment';


@Injectable({ providedIn: 'root' })


export class DataService {

  dismissLoading: any;

  messagesChanged: boolean;
  messagesChange: Subject<boolean> = new Subject<boolean>();
  messagesConversationId: string;

  environment = environment;

  properties: any = {};
  profileSocket: any;

  constructor(private localStorageService: LocalStorageService, private logger: LoggerService) {}

  toggleMessagesChanged(conversationId: string): Boolean {
    try {
      
      this.messagesConversationId = conversationId;
      this.messagesChange.next(!this.messagesChanged);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  getProperty(property: string) {
    try {

      if (!this.properties[property]) {
        this.properties[property] = JSON.parse(this.localStorageService.getItem(property));
      }

      return this.properties[property];

    } catch (error) {
      console.log(error);
      return;
    }
  }

  setProperty(property: string, data: any) {
    try {

      delete this.properties[property];

      this.properties[property] = data;

      this.localStorageService.setItem(property, JSON.stringify(data));

      return;

    } catch (error) {
      this.logger.log('DataService', 'setProperty', error, 'error');
      return;
    }
  }

  deleteProperty(property: string) {
    try {

      this.localStorageService.removeItem(property);

      delete this.properties[property];

      return;

    } catch (error) {
      this.logger.log('DataService', 'deleteProperty', error, 'error');
      return;
    }
  }

  deleteProperties() {
    try {

      const keys = Object.keys(localStorage);

      for (var i = 0; i < keys.length; i++) {
        this.localStorageService.removeItem(keys[i]);
      }

      this.properties = {};

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  setProfileSocket(socket: any) {
    try {

      this.profileSocket = socket;

      return;

    } catch (error) {
      this.logger.log('DataService', 'setProfileSocket', error, 'error');
      return;
    }
  }

  deleteProfileSocket() {
    try {

      this.profileSocket = null;

      return;

    } catch (error) {
      this.logger.log('DataService', 'deleteProfileSocket', error, 'error');
      return;
    }
  }

  
}
